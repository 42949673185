import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { apiURL } from './api';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { Identity } from './identity';
import { defaultIdentity } from 'providers/identity';
import { AuthContextProvider, useAuthContext } from 'providers/auth';

enum PostRoutes {
  GET_POSTS = '/api/v1/posts',
  GET_POST = '/api/v1/post',
  POST_ON_CHAIN = '/api/v1/post-on-chain',
  CREATE_POST = '/api/v1/post',
  COMPRESS_POST = '/api/v1/compress-post',
  LIKE_POST = '/api/v1/like-post',
}

export type PostsResponse = {
  posts: Post[];
  pageCount: number;
};

export type Post = {
  id: number;
  data: string;
  date: number;
  encoding: number;
  datastore: number;
  address: string;
  identity: Identity;
  likes: number;
  likedByUser: boolean;
};

export type CompressedPost = {
  packedData: string;
  encoding: number;
  date: number;
};

export type LikePostRequest = {
  action: 'like' | 'unlike';
  postId: number;
};

export type PostPaginationType = {
  start: number;
  limit: number;
  order: 'asc' | 'desc';
  afterId: number;
  address?: string;
};

interface CreatePostParams {
  data: string;
}

export type CompressionResult = {
  packedData: CompressedPost['packedData'];
  encoding: CompressedPost['encoding'];
  dataSizeBeforeCompression: number;
  dataSizeAfterCompression: number;
};

const fetchPosts = async ({ authToken, queryKey }: {
  authToken: string, queryKey: any
}): Promise<PostsResponse> => {
  const [_, { start, limit, order, afterId, address }] = queryKey;
  const response = await fetch(
    `${apiURL}${PostRoutes.GET_POSTS}?start=${start}&limit=${limit}&order=${order}&afterId=${afterId}${address ? `&address=${address}` : ''}`,
    {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const posts = await response.json();
  return {
    posts: posts.posts ? posts.posts.map((post: Post) => ({
      ...post,
      address: post.address,
      identity: {
        ...post.identity,
        username: post.identity.username || defaultIdentity.username,
        avatar: post.identity.avatar || defaultIdentity.avatar,
      },
    })) : [],
    pageCount: posts.pageCount,
  };
};

const likePost = async (authToken: string, likePostRequest: LikePostRequest): Promise<void> => {
  const response = await fetch(`${apiURL}${PostRoutes.LIKE_POST}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(likePostRequest),
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
}

const fetchPostOnChain = async ({ queryKey }: { queryKey: any }): Promise<Post> => {
  const [_, hash] = queryKey;
  const response = await fetch(`${apiURL}${PostRoutes.POST_ON_CHAIN}/${hash}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const createPost = async (authToken: string, post: CreatePostParams): Promise<Post> => {
  const response = await fetch(`${apiURL}${PostRoutes.CREATE_POST}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(post),
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const compressPost = async (post: CreatePostParams): Promise<CompressionResult> => {
  const response = await fetch(`${apiURL}${PostRoutes.COMPRESS_POST}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(post),
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const usePosts = ({ start, limit, order, afterId, address }: PostPaginationType) => {
  const { authenticated } = useAuthContext();
  return useQuery({
    queryKey: ['posts', { start, limit, order, afterId, address }],
    placeholderData: (previousData) => previousData,
    queryFn: () => fetchPosts({ authToken: authenticated?.token || '', queryKey: ['posts', { start, limit, order, afterId, address }] }),
  });
}

export const usePostOnChain = (hash?: string) =>
  useQuery({
    enabled: false,
    queryKey: ['post-on-chain', hash],
    queryFn: fetchPostOnChain,
    placeholderData: (previousData) => previousData,
  });

export const useCreatePost = () => {
  const { authenticated } = useAuthContext();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (post: CreatePostParams) => createPost(authenticated?.token || '', post),
    onSuccess: () => {
      toast.success('Post created');
      queryClient.invalidateQueries({ queryKey: ['posts'] });
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export const useCompressPost = () => {
  const [compressionResult, setCompressionResult] = useState<CompressionResult>();

  const mutation = useMutation({
    mutationFn: compressPost,
    onSuccess: (result) => {
      toast.success('Post compressed');
      setCompressionResult({
        packedData: result.packedData,
        encoding: result.encoding,
        dataSizeBeforeCompression: result.dataSizeBeforeCompression,
        dataSizeAfterCompression: result.dataSizeAfterCompression,
      });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return { mutation, compressionResult, setCompressionResult };
};


export const useLikePost = () => {
  const { authenticated } = useAuthContext();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (likePostRequest: LikePostRequest) => likePost(authenticated?.token || '', likePostRequest),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
    },
    onError: (error) => {
      console.error(error);
      toast.error('Failed to like/unlike');
    },
  });
}

